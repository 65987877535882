<div class="ui grid">
  <div class="one wide column">
  </div>
  <div class="fourteen wide column">
    <div class="ui piled segment" style="z-index: 1">


      <div *ngIf="!isSubmitted">
      <h1 style="text-align: center">Commission Pricing is currently on a quote basis</h1>
      <h2 style="text-align: center">For more info, e-mail me at: <a href="mailto:info@helixdoes.art">info@helixdoes.art</a></h2>
      <div style="margin: 10px 0px 10px 0px; padding: 25px;">
<form class="ui form">
  <div class="field">
    <label>Your Name</label>
    <input type="text" name="first-name" [(ngModel)]="commEmail.name" placeholder="Your Preferred Name">
  </div>
  <div class="field">
    <label>E-mail Address<br><small>This e-mail will also be used to send you a Quotation and Invoice.</small></label>
        <input type="text" name="first-name" [(ngModel)]="commEmail.email" placeholder="example@gmail.com">
  </div>
  <div class="field">
    <label>Your Social Media Handle <br><small>For commission communication and updates.</small></label>
        <input type="text" name="first-name" [(ngModel)]="commEmail.communication" placeholder="Social Media Handle">
        <select type="text" name="platform" [(ngModel)]="commEmail.platform">
          <option value="twitter" selected>Twitter</option>
          <option value="telegram">Telegram</option>
          <option value="discord">Discord</option>
        </select>
  </div>
    <div class="field">
      <label>Commission Type</label>
      <div class="ui row">
        <div class="ui col-4" style="padding-top: 5%;">
          <img src="assets/app/img/comm/icon.jpg" class="ui image" style="border-radius: 5px; border: 1px solid black;">
        </div>
        <div class="ui col-4">
          <img src="assets/app/img/comm/sketch.png" class="ui image" style="border-radius: 5px; border: 1px solid black;">
        </div>
        <div class="ui col-4">
          <img src="assets/app/img/comm/color.jpg" class="ui image" style="border-radius: 5px; border: 1px solid black;">
        </div>
      </div>
      <div class="ui row" style="margin-top: 15px;">
        <div class="ui col-4">
          <div class="field" style="border: 2px solid seagreen; border-radius: 5px; text-align: center; padding: 15px;">
            <div class="ui radio checkbox">
              <input type="radio" name="comm" id="comm-icon" tabindex="0" value="icon" (click)="commEmail.typeOfCommission = 'icon'">
              <label for="comm-icon">Icon ($80)</label>
            </div>
          </div>
        </div>
        <div class="ui col-4">
          <div class="field" style="border: 2px solid seagreen; border-radius: 5px; text-align: center; padding: 15px;">
            <div class="ui radio checkbox">
              <input type="radio" name="comm" id="comm-sketch" tabindex="0" value="sketch" (click)="commEmail.typeOfCommission = 'sketch'">
              <label for="comm-sketch">Sketch ($120)</label>
            </div>
          </div>
        </div>
        <div class="ui col-4">
          <div class="field" style="border: 2px solid seagreen; border-radius: 5px; text-align: center; padding: 15px;">
            <div class="ui radio checkbox">
              <input type="radio" name="comm" id="comm-color" tabindex="0" value="fullColor" (click)="commEmail.typeOfCommission = 'fullColor'">
              <label for="comm-color">Full Color ($250)</label>
            </div>
          </div>
        </div>
      </div>

      <div class="field" style="border: 2px solid seagreen; margin-top: 15px; border-radius: 5px; text-align: center; padding: 15px;">
        <div class="ui radio checkbox">
          <input type="radio" id="comm-other" name="comm" tabindex="0" value="other" (click)="commEmail.typeOfCommission = 'other'">
          <label for="comm-other">Other/Custom Commission</label>
        </div>
      </div>

  </div>
  <div class="field">
    <label>Number of Characters</label>
    <input type="number" name="numchars" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" [(ngModel)]="commEmail.numChars" placeholder="1">
  </div>

  <div class="field">
    <label>Estimated Quote Cost</label>
    <br>
    <p>{{estQ() === '$NaN.00' ? '$0.00' : estQ()}}</p>
    <small>This number may change based on complexity, theming, or other reasons. This number should only give you an idea of what to expect. Please see my Terms of Service for more details.</small>
  </div>

    <div class="field">
    <label>Describe your Commission Request</label>
      <textarea type="text" maxlength="3000" name="commission" [(ngModel)]="commEmail.idea" placeholder="Describe your commission in detail here..."></textarea>
  </div>
      <div class="field">
      <label>Link to any Character References</label>
      <textarea type="text" maxlength="3000" name="commission" [(ngModel)]="commEmail.references" placeholder="Please include any character reference materials here."></textarea>
  </div>
  <div class="field">
    <div class="ui checkbox">
      <input type="checkbox" tabindex="0" name="m" [(ngModel)]="commEmail.iAgree">
      <label>I agree to the Terms and Conditions</label>
    </div>
  </div>
  <button class="ui button" type="submit" [disabled]="submitting" (click)="doSubmitComm()">Submit</button> All Fields Are Required.
</form>
        </div>
      </div>
      <div *ngIf="isSubmitted">

        <h1 style="text-align: center">Thanks. I've received your form successfully.</h1>
      <h2 style="text-align: center">For more info, e-mail me at: <a href="mailto:info@helixdoes.art">info@helixdoes.art</a></h2>

      </div>

    </div>
    <div class="one wide column">
    </div>
  </div>
</div>
