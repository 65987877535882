import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {FrontComponent} from './front/front.component';
import {CommissionComponent} from './commission/commission.component';

const routes: Routes = [
  {path: '', component: FrontComponent},
  {path: 'commission', component: CommissionComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
